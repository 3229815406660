<template>
	<div class="p-login-cnlod">
		登录中...
	</div>
</template>

<script>
export default {
  data() {
    return {
      lock: false
    };
  },
  methods: {
    async login() {
      const { primary, code: c, forward = "" } = this.$route.query,
        code = encodeURIComponent(c);
      try {
        location.replace(
          `${{
            dev: "//dev.5xingfu.com:7002"
          }[this.$env.from] ||
            forward ||
            location.hostname.replace(/^\w*/gm, "//os")}#${
            (await this.$apis["/登录/大医同盟"]({ primary, code })).token
          }`
        );
      } catch (e) {
        if (!this.lock) {
          if (
            (
              await new Ajax().post(
                `//${{ dev: "api.5xingfu.com:8089" }[this.$env.from] ||
                  location.hostname.replace(
                    /^\w*/gm,
                    "register"
                  )}/litchi-server/oauth/cnlod/employee`,
                { primary, code }
              )
            ).success
          ) {
            await this.login();
          } else {
            this.$message.warn("自动注册失败");
          }
          this.lock = true;
        }
      }
    }
  },
  created() {
    this.login();
  }
};
</script>

<style lang="less">
.p-login-cnlod {
}
</style>